import { createWebHistory, createRouter } from 'vue-router';
import Axios from 'axios';

const routes = [
  {
    path: '/legacy',
    name: 'Legacy',
    component: () => import('@/contract/CRM'),
    meta: { title: 'Silae - Contrat de distribution' },
  },
  {
    path: '/april',
    name: 'April',
    component: () => import('@/contract/CRM'),
    meta: { title: 'Silae - Contrat de distribution' },
  },
  {
    path: '/newbiz',
    name: 'Newbiz',
    component: () => import('@/contract/PRM'),
    meta: { title: 'Silae - Contrat de distribution' },
  },
  {
    path: '/vip',
    name: 'VIP',
    component: () => import('@/contract/CRM'),
    meta: { title: 'Silae - Contrat de distribution' },
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: () => import('@/pages/Thanks'),
    meta: { title: 'Silae - Finalisation' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  Axios.get('/').then((res) => {
    let path = res.headers['x-silae-page'];
    if (to.path !== '/legacy' && to.path !== '/thanks' && path === 'legacy') {
      next('/legacy');
    }
    if (to.path !== '/april' && to.path !== '/thanks' && path === 'april') {
      next('/april');
    }
    if (to.path !== '/newbiz' && to.path !== '/thanks' && path === 'newbiz') {
      next('/newbiz');
    }
    if (to.path !== '/vip' && to.path !== '/thanks' && path === 'vip') {
      next('/vip');
    }
    next();
    document.title = to.meta.title;
  });
});

export default router;
