import { configure, defineRule } from 'vee-validate';
import { string, date } from 'yup';
import IBAN from 'iban';

configure({
  validateOnBlur: true,
  validateOnChange: true,
  validateOnInput: false,
  validateOnModelUpdate: false,
});

// Rules
defineRule('required', (value) =>
  string()
    .required()
    .isValid(value)
    .then((res) => (res ? true : 'Le champ est obligatoire.'))
);

defineRule('french', (value) =>
  string()
    .matches(/^[0-9a-zàâçéèêëîïôûùüÿñæœ .-]*$/i)
    .isValid(value)
    .then((res) => (res ? true : 'Le format est incorrect.'))
);

defineRule('zip', (value) =>
  string()
    .required()
    .matches(/^[0-9]{5}$/)
    .isValid(value)
    .then((res) => (res ? true : 'Le format est incorrect.'))
);

defineRule('phone', (value) =>
  string()
    .required()
    .matches(/^0[1-9][0-9]{8}$/)
    .isValid(value)
    .then((res) => (res ? true : 'Le format est incorrect.'))
);

defineRule('siret', (value) => {
  if (value.length != 14 || isNaN(value)) return 'Le SIRET est invalide.';
  let somme = 0;
  let tmp;
  for (let cpt = 0; cpt < value.length; cpt++) {
    if (cpt % 2 == 0) {
      tmp = value.charAt(cpt) * 2;
      if (tmp > 9) tmp -= 9;
    } else tmp = value.charAt(cpt);
    somme += parseInt(tmp);
  }
  return somme % 10 === 0 ? true : 'Le SIRET est invalide.';
});

defineRule('mail', (value) =>
  string()
    .email()
    .isValid(value)
    .then((res) => (res ? true : 'Veuillez saisir une adresse e-mail valide (exemple@mail.fr).'))
);

defineRule('bic', (value) =>
  string()
    .required()
    .matches(/^[A-Z0-9]{4}[A-Z]{2}[A-Z0-9]{2}(?:[A-Z0-9]{3})?$/)
    .isValid(value)
    .then((res) => (res ? true : 'Le BIC est incorrect.'))
);

defineRule('domain', (value) =>
  string()
    .required()
    .matches(/^[a-z0-9][a-z0-9-]{2,49}$/)
    .isValid(value)
    .then((res) => (res ? true : 'Le sous-domaine est incorrect.'))
);

defineRule('date', (value) =>
  date()
    .required()
    .max(new Date().toISOString().split('T')[0])
    .isValid(value)
    .then((res) => (res ? true : 'La date est invalide.'))
);

defineRule('iban', (value) => (IBAN.isValid(value) ? true : "L'IBAN est incorrect."));

defineRule('file', (value) => {
  if (!value) return true;
  if (!['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'].includes(value.type)) {
    return 'Format non supporté';
  }
  if (value.size > 5242880) return 'Fichier trop volumineux';
  return true;
});
