<template>
  <main>
    <header>
      <img id="logo" src="./assets/img/logo.svg" class="sui-u-mx-4" />
      <SuiHeading :level="3" class="header-title">Bienvenue</SuiHeading>
    </header>
    <div class="wrapper">
      <router-view />
    </div>
    <footer>
      <a href="https://silae.fr/mentions-legales/">Mentions légales</a>&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="https://silae.fr/politique-de-confidentialite/">Politique de confidentialité</a>
    </footer>
  </main>
</template>
<style scoped lang="scss">
main {
  display: flex;
  flex-direction: column;
  background-color: var(--sui-color-grey-lightest);
  height: 100vh;
}

header {
  display: flex;
  align-items: center;
  background-color: var(--sui-color-white);
  min-height: calc(var(--unit) * 8);
}

.header-title {
  margin: 0 auto;
}

.wrapper {
  flex: 1;
  overflow: auto;
}

footer {
  display: flex;
  align-items: center;
  background-color: var(--sui-color-white);
  padding-left: calc(var(--unit) * 15);
  min-height: calc(var(--unit) * 8);

  a {
    color: var(--sui-color-grey-dark);
    text-decoration: none;
    font-weight: 600;
    margin-right: calc(var(--unit) * 2);
  }
}

@media (max-width: 768px) {
  header {
    justify-content: center;
    .header-title {
      display: none;
    }
  }

  .wrapper {
    overflow: inherit;
  }
  footer {
    min-height: unset;
    padding-left: calc(var(--unit) * 2);
    box-shadow: 0px 1px 8px rgba(74, 74, 74, 0.16);
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 375px) {
  footer a {
    display: contents;
  }
}
</style>
