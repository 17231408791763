import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import SilaeUI from '@silae/silae-ui';
import './plugins/validation';

import './assets/scss/main.scss';
import '@silae/silae-ui/dist/style.css';
import '@silae/silae-ui/dist/css/_utilities.scss';

createApp(App).use(router).use(SilaeUI).mount('#app');
